import { useState, useEffect } from "react"
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from 'react-router-dom';
import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import { lang, img_url, gotoURL } from '../lang'
import api from '../api'


SwiperCore.use([Navigation, Pagination, A11y]);
export default function Activity(props) {
    const styles = props.styles;
    SwiperCore.use([Autoplay]); // start autoplay
    const [documents, setDocuments] = useState([]);
	const navigate = useNavigate();

    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                const response = await api.get('/document');
                setDocuments(response.data.results);
                // console.log(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);

    return (
        <>
        <div className={"container "+styles["activity"]}>
            <div className={styles["title"]}>
                <div className={styles["title-h1"]}>
                    <h2>{lang("รายการ","Last ")}<span>{lang("ใหม่","Update")}</span></h2>
                </div>
            </div>
            <div className={styles["activity-container"]}>
                {/* group items  */}
                <Swiper
                    slidesPerView={5}
                    centeredSlides={false}
                    spaceBetween={50}
                    speed={900}
                    autoplay={{ delay: 3000 }}
                    grabCursor={true}
                    breakpoints={{
                      // when window width is >= 320px
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 50
                      },
                      // when window width is >= 640px
                      800: {
                        slidesPerView: 2,
                        spaceBetween: 50
                      },
                      1280 : {
                        slidesPerView: 3,
                        spaceBetween: 50
                      },
                      1536 : {
                        slidesPerView: 4,
                        spaceBetween: 50
                      }
                    }}
                    pagination={{
                        el: ".activity-swiper-pagination",
                        type: "bullets",
                        clickable: true
                    }}
                    navigation={{
                      nextEl: ".art-activity-swiper-next",
                      prevEl: ".art-activity-swiper-prev"
                    }}
                    modules={[Pagination]}
                    effect={"fade"}
                    className="activity-swiper"
                >
                    {documents.map((item, index) => (
                    <SwiperSlide className="swiper-slide" key={index}>
                        <div className={styles["card"]+" group"}>
                            <div className={styles["image"]} onClick={() => gotoURL("/item/"+item.id)}>
                                <div className={styles["image-inner"]} style={{ backgroundImage: `url(${img_url+item.img_url})` }}></div>
                                <div className={styles["shine-box"]} />
                            </div>
                            <div className={styles["info"]}>
                                <div className={styles["title"]} onClick={() => gotoURL("/item/"+item.id)}><h4>{item.name}</h4></div>
                                <div className={styles["detail"]}>{item.category_name}</div>
                            </div>
                            <span className={styles["card-end"]}></span>
                        </div>
                    </SwiperSlide>
                    ))} 
                </Swiper>
                <SwiperNavigation section="activity" />
            </div>
            <div className={styles["readmore"]}>
                <button onClick={() => navigate('/activities')}>{lang("ดูเพิ่มเติม","View more")}</button></div>
        </div>
        </>
    )
}
