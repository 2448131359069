import React, { Suspense, useEffect, useState, useRef } from "react"
import { Outlet,useLocation } from "react-router-dom"
import api from './api'
import { lang, page_url, getTextSize, cookies } from './lang'
import th_icon from '../assets/th.jpg';
import en_icon from '../assets/en.jpg';
import logo from '../assets/main-logo.png';
import Footer from './Footer'
import ScrollToTop from './ScrollToTop';
import { FaPhoneAlt, FaFacebookF, FaEnvelope, FaTwitter, FaAlignRight, FaChevronDown, FaYoutube, FaTiktok } from 'react-icons/fa';
import { BsLine } from "react-icons/bs";
import footer_styles from '../assets/css/footer.module.css';
// import CookieBanner from './CookieBanner';  
import Preloader from './Template/Preloader'
// import ReactGA, { GA4 } from "react-ga4";

const Layout = () => {
    const [menus, setMenus] = useState([]);
    const [init, setInit] = useState([]);
    const [language, setLang] = useState("th");
    const [font_size, setFontSize] = useState("small");
    const [mobile_menu, setMobileMenu] = useState(false);
    const [chk_menu, setChkMenu] = useState(1);
    const [header_color, setHeaderColor] = useState("#fff");
    const [bg_color, setBGColor] = useState("#fff");
    const location = useLocation();
    const header_ref = useRef(null);
    const body_ref = useRef(null);
  
   

    useEffect(()=>{
        setLang(prev => localStorage.getItem("lang"));
        let text_size = cookies.get('text-size');
        setFontSize(text_size);
    },[]);

    window.addEventListener('scroll', (event) => {
      if(header_ref.current){
        if (window.scrollY > 5) {
          header_ref.current.className = "drop-shadow";
        } else {
          header_ref.current.className = "";
        }
      }
    });

    const setLanguage = (lang) => {
        setLang(prev => lang);
        localStorage.setItem("lang", lang);
        window.location.reload(false);
    }

    const setFont = (text_size) => {
      setFontSize(prev => text_size);
      cookies.set('text-size', text_size, { path: '/' });
      body_ref.current.ClassName = getTextSize();
    }

    const setActive = (e,menu_id) => {
      setMenus(prev => {
          let tmp = prev;
          tmp.forEach(item => {
              item.active = false;
                if(item.menu_id === menu_id){
                    item.active = true;
                    item.show = true;
                }
                let submenus = item.child;
                submenus.forEach(element => {
                    element.active = false;
                    if(element.menu_id === menu_id){
                        item.active = true;
                        element.active = true;
                        element.show = true;
                    }
                });
          });
          return tmp;
      });
      setChkMenu(menu_id);
    }

    return (
      <div ref={body_ref} className={getTextSize()}>
      <header ref={header_ref} className="shadow-none">
          <nav>
            <div className="header-container">
                <div className="logo">
                  <div className="logo-img">
                  <a href={process.env.PUBLIC_URL + "/"}>
                  <div className="header-info">
                    <h3>ฐานข้อมูลเอกสารจีนโบราณ<br/>เกี่ยวกับการค้าบนเส้นทางสายไหมตอนใต้</h3>
                    <div className="open"></div>
                  </div>
                  </a>
                  </div>
                  <div className="top-nav">
                    <div className="tool">
                      {/* <ul className="language">
                          <li className={language==="th"?'active':''}><button onClick={()=>setLanguage("th")}>TH</button></li>
                          <li className={language==="en"?'active':''}><button onClick={()=>setLanguage("en")}>EN</button></li>
                      </ul> */}
                      <ul className="font-size">
                          <li className={font_size==="small"?'active':''}><button onClick={()=>setFont("small")}>ก</button></li>
                          <li className={font_size==="normal"?'active':''}><button onClick={()=>setFont("normal")}>ก</button></li>
                          <li className={font_size==="large"?'active':''}><button onClick={()=>setFont("large")}>ก</button></li>
                      </ul>
                    </div>
                    <div className="menu">
                      <div className="mobile-menu">
                        <button onClick={e => setMobileMenu(true)} className="mobile-nav-open">
                        <FaAlignRight/>
                        </button>
                        <div className={mobile_menu?'mobile-nav active':'mobile-nav'} style={{"background":header_color}}>
                          <ul className="top">
                            <li>
                              <a href={'#close'} onClick={e => setMobileMenu(false)}>X</a>
                            </li>
                            {/* <li className={"mobile-menu-language group"}>
                                {language==="th"?(<div><img src={th_icon} alt="ภาษาไทย" width="36" height="15" /> ไทย <FaChevronDown /></div>):''}
                                {language==="en"?(<div><img src={en_icon} alt="ENGLISH" width="36" height="15" /> Eng <FaChevronDown /></div>):''}
                                <div className="submenu invisible group-hover:visible">
                                  <ul className="">
                                    <li><button onClick={()=>setLanguage("th")}><img src={th_icon} alt="ภาษาไทย" width="36" height="15" /> ภาษาไทย</button></li>
                                    <li><button onClick={()=>setLanguage("en")}><img src={en_icon} alt="ENGLISH" width="36" height="15" /> ENGLISH</button></li>
                                  </ul>
                                </div>
                            </li> */}
                          </ul>
                          <ul className="ul-menu-nav">
                              <li className={'group'}>
                                  <a href={page_url+"article/2"}>แนะนำเอกสารจีนโบราณ</a>
                              </li>
                              <li className={'group'}>
                                  <a href={page_url+"documents"}>บันทึกเอกสารจีนโบราณ</a>
                              </li>
                              <li className={'group'}>
                                  <a href={page_url+"article/5"}>แผนที่เส้นทางสายไหมตอนใต้</a>
                              </li>
                              <li className={'group'}>
                                  <a href={page_url+"article/3"}>การลงพื้นที่</a>
                              </li>
                              <li className={'group'}>
                                  <a href={page_url+"article/4"}>ความเป็นมาของโครงการ</a>
                              </li>
                          </ul>
                          <ul className="ul-menu-info">
                            <li className="font-tool">
                              <p>{lang('ขนาดตัวอักษร','Font Size')}: </p>
                              <ul className="font-size">
                                  <li className={font_size==="small"?'active':''}><button onClick={()=>setFont("small")}>ก</button></li>
                                  <li className={font_size==="normal"?'active':''}><button onClick={()=>setFont("normal")}>ก</button></li>
                                  <li className={font_size==="large"?'active':''}><button onClick={()=>setFont("large")}>ก</button></li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="desktop-menu">
                        <ul>
                              <li className={'group'}>
                                  <a href={page_url+"article/2"}>แนะนำเอกสารจีนโบราณ</a>
                              </li>
                              <li className={'group'}>
                                  <a href={page_url+"documents"}>บันทึกเอกสารจีนโบราณ</a>
                              </li>
                              <li className={'group'}>
                                  <a href={page_url+"article/5"}>แผนที่เส้นทางสายไหมตอนใต้</a>
                              </li>
                              <li className={'group'}>
                                  <a href={page_url+"article/3"}>การลงพื้นที่</a>
                              </li>
                              <li className={'group'}>
                                  <a href={page_url+"article/4"}>ความเป็นมาของโครงการ</a>
                              </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </nav>
      </header>
      {/* <section style={{"background":bg_color}}> */}
          <Suspense fallback={<Preloader text="" />}>
            <Outlet />
          </Suspense>
          {/* <!--begin::Scrolltop Button--> */}
          <ScrollToTop />
          {/* <!--end::Scrolltop Button--> */}
      {/* </section> */}
      <div className={footer_styles.footer}>
        <Footer styles={footer_styles}/>
      </div>
      {/* <CookieBanner /> */}
      </div>
    );
}

export default Layout