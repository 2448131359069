import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';

import '../assets/css/admin/admin-styles.css';
import '../assets/css/admin/layout.css';

import Layout from '../layouts/Layout';
import Preload from '../layouts/Preload';

const Dashboard = lazy(() => import("../views/admin/MainPage"))
const HomePage = lazy(() => import("../views/admin/Home"))
const UsersPage = lazy(() => import("../views/admin/Users"))
const CategoryPage = lazy(() => import("../views/admin/Category"))
const DynastyPage = lazy(() => import("../views/admin/Dynasty"))
const VocabularyPage = lazy(() => import("../views/admin/Vocabulary"))
const DocumentPage = lazy(() => import("../views/admin/Document"))
const DocumentCreatePage = lazy(() => import("../views/admin/DocumentCreate"))
const DocumentUpdatePage = lazy(() => import("../views/admin/DocumentUpdate"))
const Article = lazy(() => import("../views/admin/Article"))
const ArticleEdit = lazy(() => import("../views/admin/ArticleEdit"))

export default function PrivateRoutes(props) {
    return (
      <>
        <Routes>
          <Route element={<Layout />} >
                <Route path='/*' element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><Dashboard /></Suspense>} />
                <Route index path="/" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><Dashboard /></Suspense>} />
                <Route path="/home" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><HomePage /></Suspense>} />
                <Route path="/users" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><UsersPage /></Suspense>} /> 
                <Route path="/category" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><CategoryPage /></Suspense>} /> 
                <Route path="/dynasty" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><DynastyPage /></Suspense>} /> 
                <Route path="/vocabulary" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><VocabularyPage /></Suspense>} /> 
                <Route path="/document" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><DocumentPage /></Suspense>} /> 
                <Route path="/document_create" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><DocumentCreatePage /></Suspense>} /> 
                <Route path="/document_update/:documentId" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><DocumentUpdatePage /></Suspense>} /> 
                <Route path="/contents" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><Article type_id="1:2" /></Suspense>} />
                <Route path="/article-edit/:articleId" element={<Suspense fallback={<Preload title={"กำลังโหลด"} />}><ArticleEdit /></Suspense>} />
          </Route>
        </Routes>
      </>
    )
  }