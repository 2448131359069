import React, {Suspense, useState, useEffect, useRef} from 'react';
import { Outlet } from "react-router-dom"
import { gotoURL } from '../utils/url'
import { dateformat } from '../utils/date'
import { page_url } from '../utils/config'
import { FaPowerOff, FaCarSide} from "react-icons/fa6";
import logo from '../assets/logo.png';

export default function Layout({children}) {
    let [name, setName] = useState('');
    const [page, setPage] = useState("dashboard");

    useEffect(() => {
      let user = JSON.parse(localStorage.getItem('user'));
      setName(user.name);
      setPage(localStorage.getItem("services_page"))
    }, []);

    const handleLogout = (event) => {
      event.preventDefault();
      localStorage.removeItem("email");
      localStorage.clear();
      window.location = page_url+'authen';
    }
  
    const handleChangeMenu = (event, page, url) => {
      event.preventDefault();
      localStorage.setItem("services_page",page)
      gotoURL(url);
    }

    return (
        <div className='page-container'>
            <div className='title-content'>
              {/* <div className='back'>
                <FaArrowLeft />
              </div> */}
              <div className='title-container'>
                <div className="title-left">
                  <img src={logo} alt="ฐานข้อมูลเอกสารจีนโบราณเกี่ยวกับการค้าบนเส้นทางสายไหมตอนใต้ มหาวิทยาลัยเชียงใหม่" />
                  <nav>
                      <ul>
                        <li onClick={e=>{handleChangeMenu(e,"dashboard","/");}} className={(page==='dashboard')?"active":""}>หน้าหลัก</li>
                        <li onClick={e=>{handleChangeMenu(e,"document","/document")}} className={(page==='document')?"active":""}>บันทึกเอกสารจีนโบราณ</li>
                        <li onClick={e=>{handleChangeMenu(e,"category","/category")}} className={(page==='category')?"active":""}>ประเภทบันทึก</li>
                        <li onClick={e=>{handleChangeMenu(e,"dynasty","/dynasty")}} className={(page==='dynasty')?"active":""}>ราชวงค์</li>
                        <li onClick={e=>{handleChangeMenu(e,"vocabulary","/vocabulary")}} className={(page==='vocabulary')?"active":""}>คลังคำศัพท์</li>
                        <li onClick={e=>{handleChangeMenu(e,"article","/contents")}} className={(page==='article')?"active":""}>บทความต่างๆ</li>
                        <li onClick={e=>{handleChangeMenu(e,"users","/users")}} className={(page==='users')?"active":""}>ผู้ใช้งาน</li>
                      </ul>
                  </nav>
                </div>
                <div className='title-detail'>
                  <h3>{name}</h3>
                  <p className="date">{dateformat(new Date(),"date_th_short")}</p>
                </div>
                <div className="logout-nav" onClick={e=>handleLogout(e)} title="ออกจากระบบ"><FaPowerOff /></div>
              </div>
              <hr />
            </div>
            <div className='main-content'><Outlet /></div>
            <div className="footer-menu">

            </div>
        </div>
    )
}