import { useState, useEffect} from "react"
import React from 'react'
import api from '../api'
import { chk_img, lang } from '../lang'
import { TagCloud } from 'react-tagcloud'

export default function Resource(props) {
    const [data, setData] = useState([]);
    const styles = props.styles;

    useEffect(()=>{
        fetchData();
    },[]);

    async function fetchData(){
      let resp = await api.get(`/keyword`);
      let tmp = [];
      resp.data.results.forEach(element => {
        tmp.push({ value: element.keyword, count: element.count });
      });
      setData(tmp);
    }

    return (
    <div className={styles["resource"]+" container"}>
        <div className="keywords-container">
            <div className={styles["title"]}>
                <div className={styles["title-h1"]}>
                    <h2>{lang("คำที่","Last ")}<span>{lang("ค้นหาบ่อยที่สุด","Update")}</span></h2>
                </div>
            </div>
            <div className={styles["keywords"]}>
                  <TagCloud
                      minSize={35}
                      maxSize={70}
                      tags={data}
                      onClick={tag => alert(`'${tag.value}' จำนวนผู้เข้าชม ${tag.count} ท่าน`)}
                    />
            </div>
        </div>
    </div>
    )
}
