import { page_url } from './config';
export function gotoURL(url){
    if(url.charAt(0)==="/"){
        window.location = page_url+url.substring(1);
    }else if(url.substring(0, 4)==='http'){
        window.location = url;
    }else{
        window.location = page_url+url;
    }
}
export function gotoLink(url){
    window.open(url);
}