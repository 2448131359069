export const month_th = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
export const month_th_mini = [ "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
export const month_en = ["January","February","March","April","May","June","July","August","September","October","November","December"];
export const month_en_mini = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
export const month_cn = ["一月", "二月", "三月", "四月", "五月", "六月", "七月","八月", "九月", "十月", "十一月", "十二月"];
export function addLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
}

export function padTo2Digits(num) {
    return String(num).padStart(2, '0');
}

export function formatDate(date) {
  return [
    date.getFullYear(),
    addLeadingZeros(date.getMonth() + 1,2),
    addLeadingZeros(date.getDate(),2),
  ].join('-');
}


export function dateformat(timestamp,type=null){
    var date = new Date(timestamp);

    var year = date.getFullYear();
    var month = month_th[date.getMonth()];
    var day = date.getDate();
    var hours = addLeadingZeros(date.getHours(),2);
    var minutes = addLeadingZeros(date.getMinutes(),2);
    var seconds = addLeadingZeros(date.getSeconds(),2);

    if(type==="date_th"){
        year = year+543;
        return day + " " + month + " " + year;
    }else if(type==="date_th_short"){
        year = year+543;
        month = month_th_mini[date.getMonth()];
        return day + " " + month + " " + year + "  " + hours + ":" + minutes + ":" + seconds + " น. "
    }else if(type==="date_th_full"){
        year = year+543;
        return "วันที่ " + day + " เดือน " + month + " พ.ศ. " + year;
    }else if(type==="wdate_th_full"){
        year = year+543;
        return "วัน"+getDay(timestamp,"th")+"ที่ " + day + " เดือน " + month + " พ.ศ. " + year;
    }else{
        return day + " " + month + " " + year + "  " + hours + ":" + minutes + ":" + seconds + " น. "
    }
}


export function getDay(timestamp,type){
    var date = new Date(timestamp);
    const day_th = ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"];
    const day_en = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const day_en_mini = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];

    if(type==="th"){
        return day_th[date.getDay()];
    }else if(type==="en"){
        return day_en[date.getDay()];
    }else{
        return day_en_mini[date.getDay()];
    }
}

export function isWeekday(timestamp){
    var date = new Date(timestamp);

    if(date.getDay()===6||date.getDay()===0){
        return false;
    }else{
        return true;
    }

}

export function daycount(date_1, date_2){
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return TotalDays;
}

export function getDateListsBetween(startDate,endDate){
    let listDate = [];
    let dateMove = new Date(startDate);
    let strDate = startDate;

    while (strDate < endDate) {
        strDate = dateMove.toISOString().slice(0, 10);
        listDate.push(strDate);
        dateMove.setDate(dateMove.getDate() + 1);
    };
    return listDate;
}

// JavaScript counts months from 0 to 11
export function getDaysInMonth(month, year) {
    let check_month = parseInt(month)-1;
    var date = new Date(year, check_month, 1,0,0,1);
    var days = [];
    while (date.getMonth() === check_month) {
        let day = date.getDate();
        // return show month in pattern: "2023-05-21"
        days.push(
            year+'-'+
            addLeadingZeros(month, 2)+'-'+
            addLeadingZeros(day, 2)
        );
        date.setDate(date.getDate() + 1);
    }
    return days;
  }