import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { lang, img_url, page_url } from './../lang'
import { FaSearch } from "react-icons/fa";
// import { useNavigate } from 'react-router-dom';
// import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import api from '../api'
import axios from 'axios';

SwiperCore.use([Navigation, Pagination]);
export default function Slide(props) {
    const [error, setError] = useState(0);
    const [msg, setMsg] = useState('');
    const [images, setImages] = useState([]);
    const [search_type, setSearchType] = useState('OPAC');
    const [keyword, setKeyword] = useState('');
    const [dynasties, setDynasties] = useState([]);
    const [ip, setIP] = useState('');
	// const navigate = useNavigate();
    const styles = props.styles;
    SwiperCore.use([Autoplay]); // start autoplay

    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                let article_id = 1;
              const response = await api.get('/gallery/'+article_id);
              setImages(response.data.results);
              const dresponse = await api.get('/dynasty');
              setDynasties(dresponse.data.results);
              
              const res = await axios.get('https://api.ipify.org/?format=json');
              let ipv4 = res.data.ip;
              setIP(ipv4);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);

    const handleSearch = async () => {

        const jsonData = {
            keyword: keyword,
            create_ip: ip,
            name: ip,
            ip: ip,
        }
        await api.post(`keyword/create`, jsonData)
        .then(response => {
            if(response.data.status===true){
                window.location = page_url+"documents?keyword="+keyword;
            }else{
                setError(1);
                setMsg(response.data.message);
            }
        })
        .catch(error => {
            setError(1);
            setMsg(error);
        });

        // e.preventDefault();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    }

    return (
        <div className="slide-container">
            {/* group items  */}
            <Swiper
                spaceBetween={5}
                slidesPerView={1}
                speed={900}
                autoplay={{ delay: 3000 }}
                pagination={{
                  type: "progressbar",
                }}
                modules={[Pagination]}
                className="slide-swiper"
            >
                {images.map((item, index) => (
                    <SwiperSlide className="swiper-slide" key={index}>
                        <div className={"card "}>
                            <div className={"image"}>
                                <div className={"image-inner"} style={{ backgroundImage: `url('${img_url + item.url}')` }}></div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={styles["search-modal"]}>
                <h5>{lang('สืบค้นข้อมูล :','Search :')}</h5>
                <fieldset>
                    <select name="search_type" id="search_type" value={search_type} onChange={e=>setSearchType(e.target.value)}>
                    {dynasties.map((item, index) => (
                        <option value={item.id} keywoord={'d-'+index}>{item.name}</option>
                    ))}
                        <option value={0}>{lang('ค้นหาทุกราชวงศ์','Search all dynasty')}</option>
                    </select>
                    <input type="text" name="keyword" id="keyword" value={keyword} onChange={e=>setKeyword(e.target.value)} onKeyDown={handleKeyDown} placeholder={lang('ค้นหาเอกสารจีนโบราณราชวงศ์ฮั่น..','Find Collection')} />
                    <button type="button" onClick={e=>handleSearch()}><FaSearch /> {lang('ค้นหา','Search')}</button>
                    <button className={styles["mobile-input"]} type="button" onClick={e=>handleSearch()}><FaSearch /> {lang('ค้นหา','Search')}</button>
                </fieldset>
            {(error===1)?
            <div className={styles["search-modal-error"]+" text-red-500"}>
                * {msg}
            </div>
            :""}
            </div>
        </div>
    )
}
