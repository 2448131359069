import logo from '../assets/logo.png';

export default function Preload(prop) {
  return (
    <div className="loader-wrapper">
      <div className="loader-info">
        <div className="logo"><img src={logo} alt='CMU - Chiang Mai University'/></div>
        <h4>Admin <span>panel</span></h4>
        <p>ฐานข้อมูลเอกสารจีนโบราณเกี่ยวกับการค้าบนเส้นทางสายไหมตอนใต้</p>
      </div>
        <div className="loader"></div>
    </div>
  );
}
