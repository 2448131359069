import React, { useEffect, lazy } from "react"
import { Routes, Route } from "react-router-dom"
import Layout from "./components/Layout"
import ReactGA from 'react-ga';

import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";

import Home from "./views/Home"
import Document from "./views/Document"
import Article from "./views/Article"
import IntroArticle from "./views/IntroArticle"
import Resource from "./views/Resource"

const TRACKING_ID = "G-F7QPLT4R31"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

let isAuthenticated = false;
let user = localStorage.getItem("user");
if(user){
  isAuthenticated = true;
}

function App() {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Routes>
        <Route path="/" element={<Layout />} >
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/documents" element={<Document categoryId="all" />} />
            <Route path="/documents/:categoryId" element={<Document categoryId="" />} />
            <Route path="/resources" element={<Document categoryId="all" />} />
            <Route path="/item/:documentId" element={<Resource />} />
            <Route path="/activities" element={<Article />} />
            <Route path="/article/:articleId" element={<Article />} />
            <Route path="/s-article/:articleId" element={<IntroArticle />} />
            <Route path="/about" element={<Article id="11" />} />
            <Route path="/services" element={<Article id="15" />} />
        </Route>
        {
          (isAuthenticated)
            ? <Route path="/admin/*" element={<PrivateRoutes />} />
            : <Route path="/admin/*" element={<PublicRoutes />} />
        }
    </Routes>
  );
}

export default App;
