import React, { useEffect, useState } from 'react'
import logo from '../../assets/logo.png';
import API from '../../utils/api';
import { FaTriangleExclamation } from "react-icons/fa6";
import { page_url } from '../../utils/config'
import axios from 'axios';

export default function AuthenPage() {
  const [username, setUsername] = useState('');
  const [pass, setPass] = useState('');
  let [error, setError] = useState('');
  let [showError, setShowError] = useState(false);
  let [ip, setIP] = useState('');
  useEffect(()=>{
    localStorage.clear();
    fetchData();
  },[]);

  async function fetchData(){
    const res = await axios.get('https://geolocation-db.com/json/');
    setIP(res.data.IPv4);
  }
  
  const handleLogin = async (event) => {
    event.preventDefault();
    setShowError(false);
    setError('');
    if(username===''||pass===''){
      setShowError(true);
      setError("กรุณาตรวจสอบข้อมูล Email หรือ Password");
    }else{
        const jsonData = {
            email: username,
            password: pass,
        } 
        await API.post(`admin/verify`,jsonData)
          .then(response => {
            if(response.data.status===true){
              if(response.data.results===true){
                  // check password
                localStorage.setItem('email',username);
                localStorage.setItem('ip',ip);
                localStorage.setItem('user',JSON.stringify(response.data.result));
                // console.log('login success');
                window.location = page_url;
              }else{
                setShowError(true);
                setError(response.data.message);
              }
            }else{
                setShowError(true);
                setError(response.data.message);
            }
          })
          .catch(error => {
            setShowError(true); 
            setError(JSON.stringify(error));
          });
    }
  }

  return (
    <div className="loader-wrapper">
      <div className="loader-info">
        <div className="logo"><img src={logo} alt='CMU - Chiang Mai University'/></div>
        <h4>Admin <span>panel</span></h4>
        <p>ฐานข้อมูลเอกสารจีนโบราณเกี่ยวกับการค้าบนเส้นทางสายไหมตอนใต้</p>
        {(showError===true)?
        <div className="error" onClick={e=>setShowError(false)}>
            <FaTriangleExclamation /> {error} 
        </div>
        :''}
      </div>
      <form className='login-form'>
        <fieldset>
          <input type="text" placeholder='Username' value={username} onChange={e=>setUsername(e.target.value)} />
        </fieldset>
        <fieldset>
          <input type="password" placeholder='Password' value={pass} onChange={e=>setPass(e.target.value)} />
        </fieldset>
      </form>
      <div className="loader-button">
        <button type="button" onClick={e=>handleLogin(e)}>เข้าสู่ระบบ</button>
      </div>
    </div>
  )
}
