import {useState, useEffect} from "react"
import { page_url, lang } from './lang'
import api from './api'

const Footer = (props) => {
    // const [menus, setMenus] = useState([]);
    // const init = props.init;
    const styles = props.styles;
    // useEffect(()=>{
    //     const fetchInit = async () =>{
    //         try {
    //           const response = await api.get('/menu?type=footer-menu');
    //           setMenus(response.data.results);
              
    //         } catch (err) {
    //             if(err.response){
    //                 //Not in 202 response range
    //                 console.log(err.response.data);
    //                 console.log(err.response.headers);
    //                 console.log(err.response.status);
    //             } else {
    //                 console.log(`Error: ${err.message}`);
    //             }
    //         }
    //       }
    //     fetchInit();
    // },[]);

  return (
    <>
    <div className={"container "+styles["copyright"]}>
            <p>{lang('Copyright © 2024 ฐานข้อมูลเอกสารจีนโบราณเกี่ยวกับการค้าบนเส้นทางสายไหมตอนใต้ All Rights Reserved.')}</p>
            <a href={page_url + "admin/authen"} target="_blank" rel="noopener noreferrer">(ระบบหลังบ้าน)</a>
    </div>
    </>
  )
}

export default Footer