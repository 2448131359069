import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import NewsCard from "./NewsCard";
import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import { lang, page_url } from '../lang'
import api from '../api'

SwiperCore.use([Navigation, Pagination, A11y]);
export default function News(props) {
    const styles = props.styles;
	const navigate = useNavigate();
    SwiperCore.use([Autoplay]); // start autoplay
    const [categories, setCategories] = useState([]);
    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                const response = await api.get('/category/home');
                setCategories(response.data.results);
                // console.log(response.data.results)
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);

    return (
        <div className={"container "+styles["news"]}>
        <div className={styles["folder-info"]}>
            <div className={styles["title"]}>
                <h2>{lang("บันทึก","Last ")}<span>{lang("เอกสารจีนโบราณ","Update")}</span></h2>
            </div>
            <ul className={styles["resource-card"]}>
            {categories.map((item, index) => (
                <li key={'c-'+index}>
                    <a href={page_url+'documents/'+item.id}>
                        <p>{item.name}</p>
                        <div className={styles["summary"]}><i className="fa-regular fa-folder-open"></i> {item.total} บทความ</div>
                    </a>
                </li>
            ))}
            </ul>
            {/* <ul className={styles["resource-card"]}>
            {resources.map((item, key) =>(
                <li key={key}>
                    <a href={item.link} target={item.target}>
                        <div className={styles["cover"]}><img src={chk_img(item.cover_img)} alt={lang(item.menu_th,item.menu_en)} width="50" height="50" /></div>
                        <p>{lang(item.menu_th,item.menu_en)}</p>
                    </a>
                </li>
            ))}
            </ul> */}
        </div>
            {/* <h2>{lang("คลัง","News ")}<span>{lang("ความรู้","and Activities")}</span></h2>
            <div className="news-container">
                <Swiper
                    spaceBetween={5}
                    slidesPerView={1}
                    speed={900}
                    autoplay={{ delay: 3000 }}
                    modules={[Pagination]}
                    pagination={{
                        el: ".group-swiper-pagination",
                        type: "bullets",
                        clickable: true
                    }}
                    navigation={{
                      nextEl: ".art-group-swiper-next",
                      prevEl: ".art-group-swiper-prev"
                    }}
                    effect={"fade"}
                    className="group-swiper"
                >
                    {article.map((item, index) => (
                        <SwiperSlide className="swiper-slide" key={index}>
                            <NewsCard key={index} item={item} styles={styles}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <SwiperNavigation section="group" />
        
            </div> */}
            {/* <div className={styles["readmore"]}>
                <button onClick={() => navigate('/activities')}>{lang("ดูเพิ่มเติม","View more")}</button></div> */}
        </div>
    )
}
