import Cookies from 'universal-cookie';
export const cookies = new Cookies();
// cookies.set('myCat', 'Pacman', { path: '/' });
// console.log(cookies.get('myCat')); 

export const page_url = 'https://silkroad.human.cmu.ac.th/';
// export const page_url = 'http://localhost:3000/';

// export const img_url = 'http://localhost/silkroad/api/images/';
export const img_url = 'https://silkroad.human.cmu.ac.th/api/images/';
export const month_th = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
export const month_th_mini = [ "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
export const month_en = ["January","February","March","April","May","June","July","August","September","October","November","December"];
export const month_en_mini = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];

export function addLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
}

export function chk_img(img){
    if(img && img !== undefined){
        return img_url + img;
    }else{
        return img_url + 'slide.jpg';
    }
}

export function lang(name_th, name_en){
    let language = localStorage.getItem("lang");
    if(language===null){
        language = "th";
        localStorage.setItem("lang","th");
    } 
    if((name_en===''||name_en===null)&&language==='en'){
        name_en = name_th;
        localStorage.setItem("warning",true);
    }

    switch(language) {
        case 'en':
            return name_en;
        default:
            return name_th;
    }
}

export function getDate(event_date){
    let day_name = event_date.substr(8,2);
    let year_name = Number(event_date.substr(0,4));
    let event_month = Number(event_date.substr(5,2))-1;
    let month_name = lang(month_th[event_month], month_en[event_month], month_en[event_month]);

    let language = localStorage.getItem("lang");
    if(language==='en'){
        return day_name+' '+month_name+' '+year_name;
    }else{
        year_name = year_name+543;
        return day_name+' '+month_name+' '+year_name;
    }
}
export function thDateFormat(input_date){
    var date = new Date(input_date);

    var year = date.getFullYear()+543;
    var month = month_th[date.getMonth()];
    var day = date.getDate();

    return day + " " + month + " " + year + "  "
}
export function enDateFormat(input_date){
    var date = new Date(input_date);

    var year = date.getFullYear();
    var month = month_en[date.getMonth()];
    var day = date.getDate();

    return day + " " + month + " " + year + "  "
}

export function getTextSize(){
    let body_text = "text-small";
    let type = cookies.get('text-size');
    if(type==='large'){
        body_text="text-large";
    }else if(type==='normal'){
        body_text="text-normal";
    }
    return body_text;
}
export function gotoURL(url){
    window.location = page_url+url.substring(1);
}
export function gotoLink(url){
    window.open(url);
}