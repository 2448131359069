/* eslint-disable no-unused-vars */
import React,{ useState, useEffect, Suspense, lazy } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import Preload from '../layouts/Preload';

import '../assets/css/admin/admin-styles.css';
import '../assets/css/admin/layout.css';

// pages
import AuthenPage from '../views/admin/AuthenPage';

export default function PublicRoutes() {
  return (
    <Routes>
        <Route path='authen' element={<AuthenPage />} />
        {/* <Route path="preload" element={<Preload />} /> */}
        <Route path='/*' element={<Navigate to='/admin/authen' replace />} />
    </Routes>
  )
}